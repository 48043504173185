<template>
    <v-container class="px-0 pt-0 mt-0">
        <v-expansion-panels multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span v-html="$t('games.infoGame', {game: gameId} )" />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <jumanjiManagerHistoricalInfo :historical="historical" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span v-text="$t('games.help')" />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-img
                        src="@/assets/img/games/jumanji/jumanji-help-equipos.png"
                        width="100%"
                        max-width="750px"
                        contain
                    />
                    <v-divider class="mx-6 mt-4" />
                    <v-img
                        src="@/assets/img/games/jumanji/jumanji-help-usuarios.png"
                        width="100%"
                        max-width="750px"
                        contain
                    />
                    <v-divider class="mx-6 mt-4" />
                    <v-img
                        src="@/assets/img/games/jumanji/jumanji-help-tarjetas.png"
                        width="100%"
                        max-width="750px"
                        contain
                    />
                    <v-divider class="mx-6 mt-4" />
                    <v-img
                        src="@/assets/img/games/jumanji/jumanji-help-controles.png"
                        width="100%"
                        max-width="1200px"
                        contain
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <span
            class="text-subtitle ml-4 mt-4"
            v-text="$t('games.adminGame')"
        />
        <v-divider class="mb-3" />
        <div class="optionsToSendCard">
            <v-expansion-panels :value="openPanels[0] ? 0 : undefined">
                <v-expansion-panel class="v-expansion-panel-content-no-padding">
                    <v-expansion-panel-header>
                        <span v-text="$t('games.teams')" />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list
                            dense
                            class="with-header-all"
                        >
                            <v-list-item-group
                                v-model="teams.selected"
                                color="black"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item, index) in teams.list"
                                    :key="index"
                                >
                                    <v-list-item-content>
                                        <div style="display: grid; grid-template-columns: 30% 70%; column-gap: 14px; ">
                                            <div>
                                                <span
                                                    style="color: green"
                                                    v-text="getTeamsUsersOnline[index]"
                                                /> / <span
                                                    class="text-color-primary"
                                                    v-text="item.usersCount"
                                                />
                                            </div>

                                            <v-list-item-title v-text="item.name" />
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels :value="openPanels[1] ? 0 : undefined">
                <v-expansion-panel class="v-expansion-panel-content-no-padding">
                    <v-expansion-panel-header>
                        <span v-text="$t('games.users')" />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list
                            dense
                            class="with-header-all"
                        >
                            <v-list-item-group
                                v-model="users.selected"
                                color="black"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item, i) in getUserFiltered"
                                    :key="i"
                                >
                                    <v-list-item-content>
                                        <div style="display: grid; grid-template-columns: 5% 65% 20%; column-gap: 5%; ">
                                            <!--                                    <v-icon
                                        color="primary"
                                        v-text="item.id ? item.pivot.team : ''"
                                    />-->
                                            <div

                                                :class="getClasForUserState(item)"
                                                style="border-radius: 50%; width: 12px; height: 12px; margin-top: 3px"
                                            />
                                            <v-list-item-title v-text="item.name" />

                                            <div
                                                v-if="item.id"
                                                class="d-flex justify-space-between"
                                            >
                                                <span
                                                    style="color: green"
                                                    v-text="(historical.usersCardsSentMap[item.id] && historical.usersCardsSentMap[item.id].size) || 0"
                                                />

                                                <!--                                  @click.prevent="alert('clic')"-->
                                                <c-icon
                                                    class="ml-2"
                                                    color="primary"
                                                    small
                                                    icon
                                                    mdi-icon="mdi-content-copy"
                                                    @click.stop="copyText(item)"
                                                />
                                            </div>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels :value="openPanels[2] ? 0 : undefined">
                <v-expansion-panel class="v-expansion-panel-content-no-padding">
                    <v-expansion-panel-header>
                        <span v-text="$t('games.cards')" />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list
                            dense
                            class="with-header-all"
                        >
                            <v-list-item-group
                                v-model="cardTypes.selected"
                                color="black"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item, i) in cardTypes.list"
                                    :key="i"
                                >
                                    <v-list-item-content>
                                        <div style="display: grid; grid-template-columns: 40% 60%; column-gap: 14px; ">
                                            <div>
                                                <span
                                                    style="color: green"
                                                    v-text="item.usedCount"
                                                /> / <span
                                                    class="text-color-primary"
                                                    v-text="item.count"
                                                />
                                            </div>

                                            <!--                                    <v-icon
                                        color="primary"
                                        v-text="item.count"
                                    />-->
                                            <v-list-item-title v-text="item.name" />
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div
            class="buttons-block-jumanji-manager"
        >
            <c-btn
                mdi-icon="mdi-restart"
                outlined
                class="buttons-jumanji-manager"
                x-small
                color="danger"
                @click="restartGame()"
            >
                <span v-text="$t('games.actions.resetGame')" />
            </c-btn>

            <c-btn
                :disabled="alternateHideSendCards === 1"
                mdi-icon="mdi-eye-off"
                outlined
                class="buttons-jumanji-manager"
                color="primary"
                x-small
                @click="hideSentCards"
            >
                <span v-text="$t('games.actions.hideSentCard')" />
            </c-btn>
            <c-btn
                :disabled="alternateHideSendCards === -1"
                mdi-icon="mdi-send"
                outlined
                class="buttons-jumanji-manager"
                small
                color="green"
                @click="sendCard"
            >
                <span
                    class="mr-4"
                    v-text="$t('games.actions.sendCard')"
                />
            </c-btn>
        </div>

        <div style="display: flex; justify-content: flex-end">
            <v-switch
                v-model="useCardOrder"
                class="px-8"
                inset
                :label="$t('games.actions.useCardOrder')"
            />
        </div>
        <confirm-dialog
            ref="confirm"
        />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from "vuex";

export default {
    name: 'JumanjiManager',
    components: { jumanjiManagerHistoricalInfo: () => import('@/components/games/jumanji/jumanjiManagerHistoricalInfo') },
    props: {
        gameId: {
            type: Number,
            required: true
        },
        teams_count: {
            type: Number,
            required: true
        },
        usersActives: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            urlMain: 'admin/games/jumanji/getDataAdminInit/' + this.gameId,
            cardTypes: {
                list: [],
                selected: null
            },
            teams: {
                list: [],
                selected: null
            },
            users: {
                list: [],
                selected: null
            },
            historical: {
                lastCardsUsedByTypes: [],
                lastCard: {},
                usersCardsSentMap: {} // map with userId and Set of cardsId received
            },
            useCardOrder: true,
            alternateHideSendCards: 0, // -1 show hide card, 0 show both and 1 show send button
            openPanels: this.getDefaultOpenpanels()
        }
    },
    computed: {
        ...mapGetters(['getUser', 'isAdmin']),
        getUserFiltered() {
            const teamId = this.teams.selected;
            if (teamId) return this.users.list.filter((user, index) => index === 0 || user?.pivot?.team === teamId);
            return this.users.list;
        },
        getTeamsUsersOnline() {
            const result = Array(this.teams_count + 1).fill(0);

            this.users.list.map((user, index) => {
                if (index > 0 && user.pivot.team <= this.teams.list.length && this.usersActives[user.id]) {
                    result[user.pivot.team]++;
                }
            });

            result[0] = result.reduce((total, current) => total + current);

            return result;
        }
    },
    watch: {
        'teams.selected': function (newVal, oldVal) {
            this.users.selected = 0;
        }

    },
    async created() {
        this.generateTeams();
        await this.fetchData();

        this.fetchInfoExtra();

        this.checkAndAnskForResetGame();
    },
    methods: {
        getDefaultOpenpanels() {
            const isOpen = !this.$vuetify.breakpoint.mobile;
            return [isOpen, isOpen, isOpen];
        },
        async sendCard() {
            try {
                const userSelectedObj = this.users.selected != null ? this.getUserFiltered[this.users.selected] : null;
                const userId = userSelectedObj?.id;
                const teamId = userSelectedObj?.pivot?.team || this.teams.selected;
                const cardTypeId = this.cardTypes.selected != null ? this.cardTypes.list[this.cardTypes.selected].id : null;

                // console.log(teamId, userId, cardTypeId)
                if (teamId == null) {
                    this.$store.dispatch('snackbar/error', 'Seleccione una opción de Equipo');
                    return;
                }
                if (this.users.selected == null) {
                    this.$store.dispatch('snackbar/error', 'Seleccione una opción de Jugador');
                    return;
                }
                if (cardTypeId == null) {
                    this.$store.dispatch('snackbar/error', 'Seleccione una opción de Tarjeta');
                    return;
                }

                let urlSendCard = `admin/games/jumanji/sendCard/${this.gameId}/${cardTypeId}`;

                if (teamId !== 0) {
                    urlSendCard += "/" + teamId;

                    if (userId) {
                        urlSendCard += "/" + userId;
                    }
                }

                const data = await axios
                    .post(urlSendCard, {
                        useCardOrder: this.useCardOrder
                    });

                this.loadInfoExtra(data.data);

                // updating cards types
                this.cardTypes.list.forEach(cardT => {
                    if (data.data.lastCard?.card_type_id === cardT.id) {
                        cardT.usedCount++; // updating the specific card used
                        this.cardTypes.list[0].usedCount++; // updating used cards general
                    }
                });

                this.alternateHideSendCards = -1;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async hideSentCards() {
            try {
                const urlSendCard = `admin/games/jumanji/hideSentCards/${this.gameId}`;
                await axios.get(urlSendCard);

                this.$store.dispatch('snackbar/success', this.$t('common.actionOK'));

                this.alternateHideSendCards = 1;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async restartGameConfirmed() {
            try {
                const urlSendCard = `admin/games/jumanji/restartGame/${this.gameId}`;
                await axios.get(urlSendCard);

                this.$store.dispatch('snackbar/success', this.$t('common.actionOK'))

                this.historical = {
                    lastCardsUsedByTypes: [],
                    lastCard: {},
                    usersCardsSentMap: {}
                };

                // updating cards used types
                this.cardTypes.list.forEach(cardT => {
                    cardT.usedCount = 0;
                });

                this.alternateHideSendCards = 1;

                this.$emit('restartGame');
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async restartGame(message) {
            if(!message || typeof message != 'string') message = this.$t('games.actions.resetGameConfirmMSG');
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    message
                )
            ) {
                this.restartGameConfirmed()
            }
        },
        async fetchData() {
            try {
                const data = await axios
                    .get(this.urlMain);

                this.initialize(data.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchInfoExtra() {
            try {
                const url = 'admin/games/jumanji/getDataAdminInfoExtra/' + this.gameId;

                const data = await axios.get(url);

                this.loadInfoExtra(data.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        loadInfoExtra(data) {
            const { lastCard, lastCardsUsedByTypes, allCardsSent, usersEntered } = data;

            this.historical.lastCard = lastCard;
            this.historical.lastCardsUsedByTypes = lastCardsUsedByTypes;

            try {
                if (usersEntered?.length) {
                    this.$emit('updateUsersActives', usersEntered);
                }

                if (allCardsSent) {
                    this.loadCardsSent(allCardsSent);
                } else {
                    this.loadCardsSent([lastCard]);
                }
            } catch (e) {
                console.log('problems loading Game info extra');
            }

            // console.log('usersCardsSentMap', this.historical.usersCardsSentMap)
        },
        /**
       * For load a Array of cards sent to some users, teams or all the game
       * */
        loadCardsSent(allCardsSent) {
            const usersCardsSentMap = this.historical.usersCardsSentMap;

            const usersAll = this.users.list;

            allCardsSent.forEach((lastCard) => {
                const forUser = lastCard.user_id;
                const forTeam = !forUser && lastCard.team;
                // const forGame = !forTeam;

                let usersFilter = forUser ? [{ id: forUser }] : usersAll;

                if (forTeam) {
                    usersFilter = usersAll.filter((user) => user?.pivot?.team === forTeam);
                }

                usersFilter.forEach((user) => {
                    if (user.id) {
                        let cardsSent = usersCardsSentMap[user.id];
                        if (!cardsSent) {
                            cardsSent = new Set();
                            this.$set(this.historical.usersCardsSentMap, user.id, cardsSent);
                        }

                        cardsSent.add(lastCard.card_id);
                        usersCardsSentMap[user.id] = cardsSent;
                    }
                });
            });

            this.historical.usersCardsSentMap = usersCardsSentMap;
        },
        initialize(data) {
            const userAll = {
                id: null,
                team: null,
                name: this.$t('common.all')
            };
            this.users.list = [userAll, ...data.users];

            data.users.forEach(user => {
                if (user.pivot.team <= this.teams.list.length) {
                    this.teams.list[user.pivot.team].usersCount++;
                }
            });

            this.teams.list[0].usersCount = data.users.length;

            // cards types
            let typesUsedCountTotal = 0;
            let typesCountTotal = 0;

            const cardTypes = data.card_types.map(cardT => {
                cardT.usedCount = data.card_types_used.find(t => t.card_type_id === cardT.id)?.count ?? 0;
                typesUsedCountTotal += cardT.usedCount;
                typesCountTotal += cardT.count;
                return cardT;
            }).sort((a, b) => a.name >= b.name ? 1 : -1);

            const cardLessUsed = {
                id: 0,
                name: this.$t('common.all'),
                count: typesCountTotal,
                usedCount: typesUsedCountTotal
            };

            this.cardTypes.list = [cardLessUsed, ...cardTypes];
            // data.card_types_used
        },
        generateTeams() {
            // generating
            // this.teams_count
            this.teams.list = Array(this.teams_count + 1).fill(1).map(
                (elem, index) => {
                    return {
                        name: index ? ('Equipo ' + index) : this.$t('common.all'),
                        usersCount: 0
                    }
                });
        },
        checkAndAnskForResetGame(){

            const today = (new Date).toISOString().split('T')[0];

            const lastCheck = `jumanjiManager-lastCheckForResetGame-${this.gameId}`;
            const value = localStorage.getItem(lastCheck);

            if(value !== today){
                localStorage.setItem(lastCheck, today);
                const message = this.$t('games.actions.recomendResetGameConfirmMSG');
                this.restartGame(message);
            }
        },
        copyText(item) {
            navigator.clipboard.writeText(item.name);

            this.$store.dispatch('snackbar/success', `${this.$t('common.text-copied')}: ${item.name}`)
        },
        getClasForUserState(item) {
            if (item.id) {
                const state = this.usersActives[item.id];

                if (state === 2) return 'user-online';
                if (state === 1) return 'user-previously-online';
                return 'user-offline';
            }
            return '';
        }
    }
}
</script>

<style scoped>

.user-online{
  background-color: green;
}
.user-previously-online{
  background-color: lightgreen;
}
.user-offline{
  background-color: red;
}

.optionsToSendCard{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}

.with-header-all .v-list-item-group > *:first-child > *:first-child{
  border-bottom: 1px solid #00b5e2 !important;
}

.buttons-jumanji-manager{
  white-space: normal;
  width: 220px;
  padding: 10px 15px 10px 10px !important;
  margin-top: 8px;
  /*text-transform: none;*/
}

.buttons-block-jumanji-manager{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin-top: 10px;
  margin-bottom: 10px;

}

@media screen and (max-width: 767px ){
  .buttons-block-jumanji-manager{
    flex-direction: column-reverse;
    align-content: center;
  }
}

</style>

<style>
.buttons-block-jumanji-manager .v-btn__content {
  white-space: normal;
  flex: auto;
}

.buttons-block-jumanji-manager .v-btn {
  min-height: 52px;
  height: 100% !important;
}
</style>
